import {
  ErrorBoundary,
  Match,
  Show,
  Switch,
  createEffect,
  createResource,
  createSignal,
} from "solid-js";
import type { Component } from "solid-js";
import CreateAndSendVerification from "../Components/createAndSendVerification";
import SearchForm from "../Components/searchForm";
import { LicenseTypeRouteService } from "../ApiRequests";
import ResultsList from "../Components/resultsList";
import { StateBoardSelectionForm } from "../Components/stateBoardSelectionForm";
import { ContentBox } from "../Components/contentBox";
import { Summary } from "../Components/summary";
import { Payment } from "../Components/payment";
import { resetPaymentStores } from "../Utilities/resetPaymentStores";

export interface Props {
  viewState?: number;
}

export const Verification: Component<Props> = (props) => {
  // eslint-disable-next-line solid/reactivity
  const [view, setView] = createSignal(props.viewState ?? 0);

  // const [selectedResults, setSelectedResults] = createSignal([]);
  // const [boardSelections, setBoardSelections] = createSignal([]);
  const [licenseTypeGroups] = createResource(
    LicenseTypeRouteService.getApiV1LicenseTypes
  );

  const handleCancel = () => {
    resetPaymentStores();
    setView(1);
  };

  createEffect(() => {
    // Hack for now to update page title
    if (view() === 0) {
      document.querySelector("#ctl00_lblPageTitle").textContent =
        "Welcome to VeriDoc";
    } else {
      document.querySelector("#ctl00_lblPageTitle").textContent =
        "Create and Send Verification";
    }
  });

  return (
    <>
      <Switch
        fallback={
          <CreateAndSendVerification
            onNext={() => setView(1)}
            showButton={true}
          />
        }
      >
        <Match when={view() === 0}>
          <CreateAndSendVerification
            onNext={() => setView(1)}
            showButton={true}
          />
        </Match>
        <Match when={view() === 1}>
          <ErrorBoundary
            fallback={(err) => (
              <ContentBox>
                <div>
                  Failed to load licenses. Please refresh & try again...
                </div>
              </ContentBox>
            )}
          >
            <Show when={licenseTypeGroups.loading}>
              <ContentBox>
                <div>Loading...</div>
              </ContentBox>
            </Show>
            <Show when={!licenseTypeGroups.loading}>
              <SearchForm
                onNext={() => setView(2)}
                onCancel={() => {
                  handleCancel();
                  setView(0);
                }}
                licenseTypeGroups={licenseTypeGroups()}
              />
            </Show>
          </ErrorBoundary>
        </Match>
        <Match when={view() === 2}>
          <ResultsList
            onNext={() => setView(3)}
            onBack={() => setView(1)}
            onCancel={handleCancel}
          />
        </Match>
        <Match when={view() === 3}>
          <StateBoardSelectionForm
            onNext={() => setView(4)}
            onBack={() => setView(2)}
            onCancel={handleCancel}
          />
        </Match>
        <Match when={view() === 4}>
          <Summary
            onNext={() => setView(5)}
            onBack={() => setView(3)}
            onCancel={handleCancel}
          />
        </Match>
        <Match when={view() === 5}>
          <Payment onBack={() => setView(4)} onCancel={handleCancel} />
        </Match>
      </Switch>
    </>
  );
};
