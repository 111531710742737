import {
  Component,
  For,
  Show,
  createEffect,
  createSignal,
  onCleanup,
} from "solid-js";
import VerificationNavigationButtons from "./verificationNavigationButtons";
import { ContentBox } from "./contentBox";
import { verificationConfirmationStore } from "../Stores/verificationsConfirmationStore";
import { verificationsRequests } from "../Stores/verificationsRequestingStore";
import { TokenizerResponse } from "../Types/BasysTypes";
import CountryCodes from "../Data/countryCodes";
import {
  ApiError,
  TransactionResponse,
  TransactionRouteService,
} from "../ApiRequests";
import { Error } from "./error";
import Info from "./info";
import { useNavigate } from "@solidjs/router";
import * as Sentry from "@sentry/browser";

export interface PaymentProps {
  onBack: () => void;
  onCancel: () => void;
}

export const Payment: Component<PaymentProps> = (props) => {
  const apiKey =
    import.meta.env.MODE === "production"
      ? "pub_2QFWgzISbxAN7610r6efh4kKlDP"
      : "pub_2MTgcHulzAz5Jk4HHeyEO22txAi";
  const paymentHost =
    import.meta.env.MODE === "production"
      ? "https://app.basysiqpro.com"
      : "https://sandbox.basysiqpro.com";
  const navigate = useNavigate();
  const [name, setName] = createSignal("");
  const [country, setCountry] = createSignal("US");
  const [cardNumber, setCardNumber] = createSignal("");
  const [cvv, setCvv] = createSignal("");
  const [expirationMonth, setExpirationMonth] = createSignal("");
  const [expirationYear, setExpirationYear] = createSignal("");
  // const [street, setStreet] = createSignal("");
  // const [city, setCity] = createSignal("");
  // const [state, setState] = createSignal("");
  const [zip, setZip] = createSignal("");
  const [contactName, setContactName] = createSignal("");
  const [contactPhone, setContactPhone] = createSignal("");
  const [contactEmail, setContactEmail] = createSignal("");
  const [contactEmailConfirmation, setContactEmailConfirmation] =
    createSignal("");
  const [paymentProcessingMessage, setPaymentProcessingMessage] =
    createSignal("");

  const [errorMessage, setErrorMessage] = createSignal("");
  const [expirationError, setExpirationError] = createSignal("");
  const [emailMatchError, setEmailMatchError] = createSignal("");
  const [cardNumberError, setCardNumberError] = createSignal("");

  createEffect(() => {
    setCardNumber(
      cardNumber()
        .replace(/[^0-9]/g, "")
        .substring(0, 20)
    );
  });

  const validateExpirationDate = () => {
    if (
      parseInt(expirationMonth()) < 1 ||
      parseInt(expirationMonth()) > 12 ||
      parseInt(expirationYear()) < new Date().getFullYear() ||
      (parseInt(expirationYear()) === new Date().getFullYear() &&
        parseInt(expirationMonth()) < new Date().getMonth() + 1)
    ) {
      setExpirationError("Invalid expiration date.");
    } else {
      setExpirationError("");
    }
  };

  const validateEmailMatch = () => {
    if (contactEmail() !== contactEmailConfirmation()) {
      setEmailMatchError("Emails do not match.");
    } else {
      setEmailMatchError("");
    }
  };

  const setError = (error: string) => {
    setErrorMessage(error);
    setPaymentProcessingMessage("");
  };

  let YearInputElement: HTMLInputElement;
  const setMonthAndUpdateCursor = (value: string) => {
    setExpirationMonth(value);
    if (value.length === 2) {
      YearInputElement.focus();
    } else if (value > "1") {
      YearInputElement.focus();
    }
  };

  const handleSubmit = async (e: Event) => {
    e.preventDefault();

    // if (!cardNumber() || !cvv() || !expirationMonth() || !expirationYear() || !zip() || !contactName() || !contactPhone() || !contactEmail() || !contactEmailConfirmation()) {
    //     return true;
    // }
    if (!cardNumber()) {
      setError("Please enter a card number.");
      return true;
    }
    if (!cvv()) {
      setError("Please enter a CVV.");
      return true;
    }
    if (!expirationMonth()) {
      setError("Please enter an expiration month.");
      return true;
    }
    if (!expirationYear()) {
      setError("Please enter an expiration year.");
      return true;
    }
    if (!zip() && country() == "US") {
      setError("Please enter a zip code.");
      return true;
    }
    if (!contactName()) {
      setError("Please enter a contact name.");
      return true;
    }
    if (!contactPhone()) {
      setError("Please enter a contact phone number.");
      return true;
    }
    if (!contactEmail()) {
      setError("Please enter a contact email.");
      return true;
    }
    if (!contactEmail().includes("@")) {
      setError("Please enter a valid email.");
      return true;
    }
    if (!contactEmailConfirmation()) {
      setError("Please confirm the contact email.");
      return true;
    }

    validateExpirationDate();
    validateEmailMatch();

    if (!expirationError() && !emailMatchError() && !cardNumberError()) {
      let tokenResponse: TokenizerResponse = null;
      let transactionResponse: TransactionResponse;
      let transactionStep = 0;
      try {
        setPaymentProcessingMessage("Validating credit card information...");
        const response = await fetch(paymentHost + "/api/tokenizer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: apiKey,
            Referer: paymentHost + "/api/tokenizer/" + apiKey,
          },
          body: JSON.stringify({
            tsid: verificationConfirmationStore.publicTransactionId,
            payment_method: {
              card: {
                entry_type: "keyed",
                number: cardNumber(),
                expiration_date: `${expirationMonth().padStart(
                  2,
                  "0"
                )}/${expirationYear().padStart(4, "20")}`,
                cvc: cvv(),
              },
            },
          }),
        });

        tokenResponse = (await response.json()) as TokenizerResponse;

        if (!response.ok || tokenResponse.status !== "success") {
          if (
            tokenResponse.msg ===
            "general error: invalid card number - fails Luhn10 check"
          ) {
            tokenResponse.msg = "Invalid card number.";
          } else if (tokenResponse.msg === "general error: invalid cvc") {
            tokenResponse.msg = "Invalid CVC.";
          } else if (
            tokenResponse.msg ===
            "binding error: invalid year in expiration date"
          ) {
            tokenResponse.msg = "Invalid Year in Expiration Date.";
          } else if (
            tokenResponse.msg ===
            "binding error: invalid month in expiration date"
          ) {
            tokenResponse.msg = "Invalid Month in Expiration Date.";
          } else {
            Sentry.captureException(
              "Unexpected Tokenizer Error: " + tokenResponse.msg
            );
          }
          setError(
            tokenResponse.msg || "An error occurred verifying the credit card."
          );
          return;
        } else {
          setError("");
          setPaymentProcessingMessage("Processing payment...");
          transactionStep = 1;

          transactionResponse =
            await TransactionRouteService.postApiV1TransactionsPayment({
              requestBody: {
                processorConfirmationToken: tokenResponse.data.token,
                publicTransactionId:
                  verificationConfirmationStore.publicTransactionId,
                contactName: contactName(),
                contactPhone: contactPhone(),
                contactEmail: contactEmail(),
                billingFirstName: name(),
                billingLastName: "",
                billingAddressLine1: "",
                billingAddressLine2: "",
                billingCity: "",
                billingState: "",
                billingZip: zip(),
                billingCountry: country(),
              },
            });
          if (transactionResponse.paymentCompleted) {
            transactionStep = 2;
            setError("");
            setPaymentProcessingMessage("Processing completed...");
            navigate(
              "/thankyou/" + verificationConfirmationStore.publicTransactionId
            );
            return;
          }
        }
      } catch (error) {
        const paymentStatus = paymentProcessingMessage();
        if (error instanceof ApiError) {
          setError("An error occurred during payment. " + error.body);
          if (logPaymentError(error.body)) {
            Sentry.captureException(error, {
              extra: {
                body: error.body,
                status: error.status,
                url: error.url,
                request: error.request,
                paymentStatus: paymentStatus,
              },
            });
          }
        } else {
          if (transactionStep === 0) {
            setError(
              "An error occurred during payment.  The credit card information could not be validated. Your network may be blocking our credit card validation service.  Please contact your network administrator to allow access to https://app.basysiqpro.com in order to process your payment.  If you experience further difficulty, please contact support@veridoc.org."
            );
            Sentry.captureEvent({});
          } else {
            setError("An error occurred during payment. " + error);
            Sentry.captureException(error, {
              extra: {
                tokenUrl: paymentHost + "/api/tokenizer",
                tokenResponse: tokenResponse,
                paymentUrl: paymentHost + "/api/payment",
                transactionResponse: transactionResponse,
                errorMessageForUser: errorMessage(),
                paymentStatus: paymentStatus,
              },
            });
          }
        }
        setPaymentProcessingMessage("");
      }
    }
  };
  const logPaymentError = (body: string) => {
    if (body === "Credit Card Error - declined") return false;
    return true;
  };

  return (
    <div class="text-left">
      <h1 class="text-4xl">Verification Payment</h1>
      <ContentBox>
        <div class="text-left my-2">
          A valid American Express, Discover, Mastercard or Visa is required for
          payment. No other method of payment can be accepted.
        </div>

        <form onSubmit={handleSubmit}>
          <h2 class="text-2xl font-bold text-left mt-8 mb-4">
            Credit Card Information
          </h2>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Name on Card</span>
              <input
                name="name"
                type="text"
                value={name()}
                onInput={(e) => setName(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                autofocus
                required
              />
              <span class="block text-sm italic">
                Must enter name exactly as it appears on credit card.
              </span>
            </label>
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Card Number</span>
              <input
                name="cardNumber"
                type="text"
                value={cardNumber()}
                maxLength={20}
                onInput={(e) => setCardNumber(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                required
              />
              <span class="block text-sm italic">
                Enter with no spaces or dashes.
              </span>
            </label>
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Card Security Code</span>
              <input
                name="cvv"
                type="text"
                value={cvv()}
                onInput={(e) => setCvv(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                maxlength={4}
                required
              />
              <span class="block text-sm italic">
                3-4 digit card security code, also known as CVV2 or CVC2.
              </span>
            </label>
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Expiration</span>
              <input
                name="expirationMonth"
                placeholder="MM"
                type="text"
                maxLength={2}
                value={expirationMonth()}
                onInput={(e) => setMonthAndUpdateCursor(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1 w-14 px-2 mr-1"
                required
              />
              /
              <input
                name="expirationYear"
                placeholder="YYYY"
                ref={YearInputElement}
                min={new Date().getFullYear()}
                max={new Date().getFullYear() + 50}
                type="text"
                maxLength={4}
                value={expirationYear()}
                onInput={(e) => setExpirationYear(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1 ml-1  w-20 px-2"
                required
              />
            </label>
            {expirationError() && (
              <div class="text-red-600">{expirationError()}</div>
            )}
          </div>
          <div class="mb-4 text-left">
            <label class="block mt-4" for="billing-country">
              Country
            </label>
            <select
              title="Country"
              id="billing-country"
              class="bg-white border-solid border-2 border-gray-300 rounded"
              onChange={(e) => setCountry(e.target.value)}
            >
              <For each={Object.keys(CountryCodes)}>
                {(key) => (
                  <option value={key} selected={key == country()}>
                    {CountryCodes[key]}
                  </option>
                )}
              </For>
            </select>
          </div>
          <div class="mb-4 text-left">
            <label class="">
              <span class="block">Zip Code</span>
              <input
                name="zip"
                type="text"
                value={zip()}
                onInput={(e) => setZip(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                required
              />
            </label>
          </div>
          {/* <h2 class="text-2xl font-bold text-left">Billing Address Information</h2>
                    <div class="mb-4 text-left">
                        <label>
                            <span class="block">Street</span>
                            <input name="street" type="text" value={street()} onInput={(e) => setStreet(e.currentTarget.value)} class="bg-white border-solid border-2 border-gray-300 rounded mb-1" required />
                            <span class="block text-sm italic">Must enter the billing address of credit card.</span>
                        </label>
                    </div>
                    <div class="mb-4 text-left flex">
                        <label class="">
                            <span class="block">City</span>
                            <input name="city" type="text" value={city()} onInput={(e) => setCity(e.currentTarget.value)} class="bg-white border-solid border-2 border-gray-300 rounded mb-1" required />
                        </label>
                        <label class="">
                            <span class="block">State</span>
                            <input name="state" type="text" value={state()} onInput={(e) => setState(e.currentTarget.value)} class="bg-white border-solid border-2 border-gray-300 rounded mb-1" required />
                        </label>
                        <label class="">
                            <span class="block">Zip</span>
                            <input name="zip" type="text" value={zip()} onInput={(e) => setZip(e.currentTarget.value)} class="bg-white border-solid border-2 border-gray-300 rounded mb-1" required />
                        </label>
                    </div> */}
          {/* Contact Information */}

          <h2 class="text-2xl font-bold text-left">Contact Information</h2>
          <div class="text-left italic mb-4">
            Enter information for the individual to contact regarding questions
            on this transaction.
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Name</span>
              <input
                name="contactName"
                type="text"
                value={contactName()}
                onInput={(e) => setContactName(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                required
              />
            </label>
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Phone Number</span>
              <input
                name="contactPhone"
                type="text"
                value={contactPhone()}
                onInput={(e) => setContactPhone(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                required
              />
            </label>
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Email</span>
              <input
                name="contactEmail"
                type="email"
                value={contactEmail()}
                onInput={(e) => setContactEmail(e.currentTarget.value)}
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                required
              />
            </label>
            <div class="text-left italic block">
              The receipt of transaction will be emailed here.
            </div>
          </div>
          <div class="mb-4 text-left">
            <label>
              <span class="block">Re-type Email</span>
              <input
                name="contactEmailConfirmation"
                type="text"
                value={contactEmailConfirmation()}
                onInput={(e) =>
                  setContactEmailConfirmation(e.currentTarget.value)
                }
                class="bg-white border-solid border-2 border-gray-300 rounded mb-1"
                required
              />
            </label>
            {emailMatchError() && (
              <div class="text-red-600">{emailMatchError()}</div>
            )}
          </div>

          <Show when={errorMessage()}>
            <Error message={errorMessage()} />
          </Show>
          <Show when={paymentProcessingMessage()}>
            <Info message={paymentProcessingMessage()} />
          </Show>
          <VerificationNavigationButtons
            onSubmit={handleSubmit}
            onBack={props.onBack}
            onCancel={props.onCancel}
            onSubmitDisabled={paymentProcessingMessage() != ""}
          />
        </form>
      </ContentBox>
    </div>
  );
};
