import { A } from "@solidjs/router";
import {
  For,
  Match,
  Switch,
  createEffect,
  createResource,
  type Component,
  createSignal,
  Show,
} from "solid-js";
import {
  ApiError,
  ContactRequest,
  ContactRouteService,
  LicenseTypeRouteService,
} from "../ApiRequests";
import { ContentBox } from "../Components/contentBox";
import * as Sentry from "@sentry/browser";
import Info from "../Components/info";

const filterDuplicates = (
  v: { groupId: string; name: string },
  i: number,
  a: { groupId: string; name: string }[]
): boolean => a.findIndex((t) => t.groupId === v.groupId) === i;
const sortPhysicianFirst = (
  a: { groupId: string; name: string },
  b: { groupId: string; name: string }
): number => {
  if (a.name.startsWith("Physician")) return -1;
  if (b.name.startsWith("Physician")) return 1;
  return a.name.localeCompare(b.name);
};
export const ContactUsPage: Component = () => {
  const [email, setEmail] = createSignal("");
  const [retypeEmail, setRetypeEmail] = createSignal("");
  const [name, setName] = createSignal("");
  const [profession, setProfession] = createSignal("");
  const [phone, setPhone] = createSignal("");
  const [subject, setSubject] = createSignal("");
  const [questions, setQuestions] = createSignal("");
  const [emailErrorMsg, setEmailErrorMsg] = createSignal("");
  const [errorMessage, setErrorMessage] = createSignal("");
  const [successMessage, setSuccessMessage] = createSignal("");

  const [licenseTypeGroups] = createResource(
    LicenseTypeRouteService.getApiV1LicenseTypes
  );

  createEffect(() => {
    // Hack for now to update page title
    document.querySelector("#ctl00_lblPageTitle").textContent = "Contact Us";
  });

  createEffect(() => {
    if (email() !== retypeEmail() && retypeEmail() !== "" && email() !== "") {
      setEmailErrorMsg("Emails do not match");
    } else {
      setEmailErrorMsg("");
    }

    if (email() !== "" && !email().includes("@")) {
      setEmailErrorMsg("Email is not valid");
    }
  });

  const submitEnabled = () => {
    return (
      email() &&
      retypeEmail() &&
      name() &&
      profession() &&
      subject() &&
      questions() &&
      emailErrorMsg() === ""
    );
  };

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    const token = await grecaptcha.execute(
      "6Lc4dKsaAAAAAC19W1N354oeg7CDX7WIusazgdRc",
      { action: "submit" }
    );

    const request: ContactRequest = {
      email: email(),
      emailConfirm: retypeEmail(),
      name: name(),
      phone: phone(),
      subject: subject(),
      questions: questions(),
      profession: profession(),
      captchaToken: token,
    };

    try {
      const response = await ContactRouteService.postApiV1Contact({
        requestBody: request,
      });
      if (response) {
        setSuccessMessage(
          "Thank you for your inquiry. Someone should be contacting you shortly."
        );
      }
    } catch (error) {
      if (error instanceof ApiError) {
        const errorBodyStr = error.body ? error.body.toString() : "";

        if (error.status === 400 && errorBodyStr.includes("CAPTCHA")) {
          setErrorMessage(
            "We are unable to process your request at this time as our Captcha system cannot authenticate your request. Please try again on another device."
          );
          Sentry.captureEvent({
            message: "Captcha error",
            extra: {
              body: errorBodyStr,
              status: error.status,
              url: error.url,
              request: error.request,
              errorMessage: errorMessage(),
            },
          });
        } else {
          Sentry.captureException(error, {
            extra: {
              body: errorBodyStr,
              status: error.status,
              url: error.url,
              request: error.request,
              errorMessage: errorMessage(),
            },
          });
        }
      } else {
        // Capture other types of errors, including unhandled promise rejections
        Sentry.captureException(error, {
          extra: { errorMessage: errorMessage() },
        });
      }
    }
  };

  return (
    <div class="text-left">
      <Switch fallback={<div>Problem displaying page. Please refresh.</div>}>
        <Match when={licenseTypeGroups.loading}>
          <h1 class="text-4xl">Contact Us</h1>
          <ContentBox>
            <div>Loading...</div>
          </ContentBox>
        </Match>
        <Match when={successMessage()}>
          <h1 class="text-4xl">Thank You</h1>
          <Info message={successMessage()} />
        </Match>
        <Match when={!licenseTypeGroups.loading}>
          <h1 class="text-4xl">Contact Us</h1>
          <ContentBox>
            <div class="text-left mt-4 mb-8">
              If you have any questions, please contact us at{" "}
              <a href="mailto:support@veridoc.org">support@veridoc.org</a> or
              use the form below:
            </div>
            <form>
              <div class="grid grid-cols-3 gap-2 max-w-2xl">
                <label class="text-left pr-2">
                  Email: <span class="text-red-600">*</span>
                </label>
                <div class="col-span-2 mb-1 text-left">
                  <input
                    name="email"
                    aria-required="true"
                    type="text"
                    value={email()}
                    onInput={(e) => setEmail(e.currentTarget.value)}
                    class="bg-white border-solid border-2 border-gray-300 rounded w-full"
                    required
                  />
                  {emailErrorMsg() && (
                    <div class="text-red-600">{emailErrorMsg()}</div>
                  )}
                </div>

                <label class="text-left pr-2">
                  Re-Type Email: <span class="text-red-600">*</span>
                </label>
                <input
                  name="cvv"
                  type="retypeEmail"
                  value={retypeEmail()}
                  onInput={(e) => setRetypeEmail(e.currentTarget.value)}
                  class="bg-white border-solid border-2 border-gray-300 rounded col-span-2 mb-1"
                  required
                />

                <label class="text-left pr-2">
                  Name: <span class="text-red-600">*</span>
                </label>
                <input
                  name="cvv"
                  type="name"
                  value={name()}
                  onInput={(e) => setName(e.currentTarget.value)}
                  class="bg-white border-solid border-2 border-gray-300 rounded col-span-2 mb-1"
                  required
                />

                <label class="text-left pr-2">
                  Profession: <span class="text-red-600">*</span>
                </label>
                <select
                  name="profession"
                  class="bg-white border-solid border-2 border-gray-300 rounded col-span-2 mb-1"
                  onChange={(e) => setProfession(e.target.value)}
                >
                  <option
                    value="Select a profession"
                    disabled
                    selected={!setProfession()}
                  >
                    Select a profession
                  </option>
                  <For
                    each={[
                      { name: "Physician" },
                      { name: "Physician Assistant" },
                      ...licenseTypeGroups()
                        .map((g) => ({ groupId: g.groupId, name: g.group }))
                        .filter(filterDuplicates)
                        .filter((g) => !g.name.startsWith("Physician")),
                    ]}
                  >
                    {(licenseType) => (
                      <option value={licenseType.name}>
                        {licenseType.name}
                      </option>
                    )}
                  </For>
                  <option value="Other">Other</option>
                </select>
                <label class="text-left pr-2">Phone:</label>
                <input
                  name="cvv"
                  type="phone"
                  value={phone()}
                  onInput={(e) => setPhone(e.currentTarget.value)}
                  class="bg-white border-solid border-2 border-gray-300 rounded col-span-2 mb-1"
                />

                <label class="text-left pr-2">
                  Subject: <span class="text-red-600">*</span>
                </label>
                <input
                  name="cvv"
                  type="subject"
                  value={subject()}
                  onInput={(e) => setSubject(e.currentTarget.value)}
                  class="bg-white border-solid border-2 border-gray-300 rounded col-span-2 mb-1"
                  required
                />

                <label class="text-left pr-2">
                  Questions or Comments: <span class="text-red-600">*</span>
                </label>
                <textarea
                  name="questions"
                  value={questions()}
                  onInput={(e) => setQuestions(e.currentTarget.value)}
                  class="bg-white border-solid border-2 border-gray-300 rounded col-span-2 mb-1"
                  rows={7}
                  required
                />
                <div class="text-left col-start-2">
                  <Show when={!submitEnabled()}>
                    <button
                      type="button"
                      class="py-3 px-5 mr-5 rounded-md bg-sky-800 text-white bg-opacity-50 hover:cursor-not-allowed"
                    >
                      Submit
                    </button>
                  </Show>
                  <Show when={submitEnabled()}>
                    <button
                      type="button"
                      class="py-3 px-5 mr-5 rounded-md bg-sky-800 hover:bg-sky-900 text-white"
                      onClick={onSubmit}
                    >
                      Submit
                    </button>
                  </Show>
                </div>
              </div>
              <div class="text-left mt-4">
                VeriDoc Inc.
                <br />
                4309 Kodiak Place
                <br />
                Bismarck, ND 58503
                <br />
                (701) 319-6500
                <br />
              </div>
            </form>
          </ContentBox>
        </Match>
      </Switch>
    </div>
  );
};
export default ContactUsPage;
