/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ActiveLicenseBoardResults } from '../models/ActiveLicenseBoardResults';
import type { LicenseSearchRequest } from '../models/LicenseSearchRequest';
import type { LicenseSearchResults } from '../models/LicenseSearchResults';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LicenseRouteService {

    /**
     * Returns a list of licenses that match the search criteria. Use authentication here if possible but not required. If not authenticated, additional parameters will be needed in the future.
     * @returns LicenseSearchResults Success
     * @throws ApiError
     */
    public static postApiV1LicensesSearch({
requestBody,
}: {
requestBody: LicenseSearchRequest,
}): CancelablePromise<Array<LicenseSearchResults>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/licenses/search',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
            },
        });
    }

    /**
     * Returns a list of active license boards that VeriDoc processes and the license types they support.
     * @returns ActiveLicenseBoardResults Success
     * @throws ApiError
     */
    public static getApiV1LicensesBoards(): CancelablePromise<ActiveLicenseBoardResults> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/licenses/boards',
        });
    }

}
